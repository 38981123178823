/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from 'moment';
import _ from 'lodash';
import { INNER_ROUTES } from '../../../embed/src/constants/inner-routes-values';
import config from '../../../config/env';

function ThankYou({
  show, providers, provider, service, dateSlot, centre, t, getPrice,
}) {
  return (
    <>
      {show === true ? (
        <ith-div class="border mt-3">
          <ith-div class="flex justify-between md:p-5 p-3 md:py-6 py-4 cursor-pointer select-none">
            <ith-div class="font-semibold text-gray-300 tracking-tight w-full  ">
              <ith-div class="flex md:flex-row md:justify-between flex-col">
                <ith-div class="flex flex-col">
                  <ith-p className="flex">
                    <CheckCircleIcon
                      className="text-green-700 flex items-center justify-center rounded-full mr-5 text-md h-6 w-6"
                    />
                    {`${t('label_thankYou')} ${t('label_bookingSuccess')}`}
                  </ith-p>
                  <ith-p class="ml-12 mt-4">
                    {
                    `${_.find(providers, ['centreProvider_id', provider.centreProvider_id]).User.firstName
                    } ${
                      _.find(providers, ['centreProvider_id', provider.centreProvider_id]).User.lastName}`
                  }
                  </ith-p>
                  <ith-p class="ml-12">
                    {
                    `${service.name
                    }, ${
                      service.duration
                    } min ${
                      getPrice(service, centre)}`
                  }
                  </ith-p>
                  <ith-p class="ml-12">
                    {
                    `${moment(dateSlot.date).format('LL')
                    } ${
                      dateSlot.startTime}`
                  }
                  </ith-p>
                </ith-div>
                <ith-div class="flex md:justify-end md:items-end items-start ml-12 md:mt-2 mt-10  ">
                  <ith-button
                    class="gridColor inline-flex justify-center w-40 items-center px-4 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-green-100 focus:outline-none"
                    onClick={() => window.open(`${config.api.consumerApps}/${INNER_ROUTES.DASHBOARD.APPOINTMENT}`, '_blank')}
                  >
                    {t('L.BookingSummary')}
                  </ith-button>
                </ith-div>
              </ith-div>
            </ith-div>
          </ith-div>
        </ith-div>
      ) : ''}
    </>
  );
}
export default ThankYou;
