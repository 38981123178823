import { publicCommonClient } from '../clients';

let controller = new AbortController();

const searchInterface = {
  getSuggestions: async (query) => {
    controller.abort();
    controller = new AbortController();

    const { data: suggestions } = await publicCommonClient.get(
      `/api/search/getSuggestions/${query}`,
      { signal: controller.signal },
    );

    return suggestions;
  },
  getResults: async (query) => {
    const { data: results } = await publicCommonClient.get(
      `/api/search/getResults/${query}/8.55/47.36667`,
    );

    return results;
  },
  getResult: async (centreId) => {
    const { data: results } = await publicCommonClient.get(
      `/api/search/getResult/centre/${centreId}`,
    );

    return results;
  },
};

export default searchInterface;
