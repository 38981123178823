import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { MinimalWidgetPage } from './embed/minimal';
// import { EmbedBookingPage } from './embed/booking';
import './index.css';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
const embedElement = document.getElementById('embed-booking-page');

const root = ReactDOM.createRoot(rootElement ?? embedElement);

root.render(
  <React.StrictMode>
    {rootElement ? (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    ) : (
      <MinimalWidgetPage />
    )}
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
