/* eslint-disable no-unused-vars */

/* eslint-disable react/jsx-no-useless-fragment */

/* eslint-disable no-unused-expressions */

/* eslint-disable no-mixed-operators */

/* eslint-disable no-shadow */

/* eslint-disable prefer-destructuring */

/* eslint-disable no-underscore-dangle */

/* eslint-disable consistent-return */

/* eslint-disable no-use-before-define */
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Button, Modal } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';

import appointmentService from '../../../embed/src/api/api/appointmentService';
import {
  createSlot,
  deleteSlot,
  getListSlot,
} from '../../../embed/src/api/appointmentBookedSlot';
import Countdown from '../../../embed/src/components/Countdown';
import { DATE_FORMAT } from '../../../embed/src/constants/dateConstants';
import DateTime from '../../../embed/src/utils/date-time';

function BookingScreen({
  click,
  show,
  setprovider,
  setDate,
  provider,
  setservice,
  service,
  checked,
  visible,
  dateSlot,
  providers,
  serviceVariants,
  centre,
  t,
  getPrice,
  preselectedProviderId,
  preselectedProviderShowSelected,
  preselectedProviderSelectButHide,
  preselectedServiceId,
  preselectedServiceShowSelected,
  preselectedServiceSelectButHide,
}) {
  const WEEK_NAMES = [
    'labelShort_sunday',
    'labelShort_monday',
    'labelShort_tuesday',
    'labelShort_wednesday',
    'labelShort_thursday',
    'labelShort_friday',
    'labelShort_saturday',
  ];

  const [selectedDay, setselectedDay] = useState(moment().startOf('day'));
  const [providerOffer, setproviderOffer] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [nextProviderOfferFullArray, setNextProviderOfferFullArray] = useState(
    [],
  );
  const [firstAvailableDate, setfirstAvailableDate] = useState(null);
  const [bookedInfomation, setBookedInformation] = useState(null);
  const [serviceVariant, setserviceVariant] = useState(serviceVariants);
  const [highlightedTimeSlot, sethighlightedTimeSlot] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [amountOfMostSlots, setAmountOfMostSlots] = useState('');
  const [listPreBooked, setListPreBooked] = useState([]);
  const [defaultValue, setDefaultValue] = useState(false);

  const checkAvailable = (timeSlot, listPreBooked) => {
    const startTime = moment(
      `${timeSlot.date} ${timeSlot.startTime}`,
      'YYYY-MM-DD HH:mm',
    ).add(1, 'minute');
    const isConflict = _.some(listPreBooked, (preBooked) => {
      const startBooked = moment(
        `${preBooked.date} ${preBooked.startTime}`,
        'YYYY-MM-DD HH:mm',
      );
      const endBooked = moment(
        `${preBooked.date} ${preBooked.endTime}`,
        'YYYY-MM-DD HH:mm',
      );
      return startTime.isBetween(startBooked, endBooked);
    });
    return {
      ...timeSlot,
      booked: !!isConflict,
    };
  };

  // useEffect to setDefault Values only once
  useEffect(() => {
    setDefaultValue(true);
    setIsFetched(false);
    fetchPreBookedList();
  }, []);

  const fetchPreBookedList = async () => {
    const response = await getListSlot(
      provider.centreProvider_id,
      service.booking_id,
    );
    setListPreBooked(response);
  };

  const visibleDays = useVisibleDay();

  const [providerOfferFetchedFirstTime, setProviderOfferFetchedFirstTime] = useState(false);

  const nextProviderOffer = [];
  let firstDayOfNextWeek = '';
  let lastDayOfNextWeek = '';

  useEffect(() => {
    if (!isFetched && defaultValue) {
      fetchProviderOffer(provider, service);
    }
  });

  const fetchProviderOffer = async (provider) => {
    try {
      const providerId = provider.centreProvider_id;
      const centreId = centre.centre_id;
      const centreBookingLimits = centre.CentreSetting;
      const serviceId = service.service_id;

      if (!providerId || !centreId) {
        return;
      }
      const params = {
        centreId,
        providerId,
      };
      const { bookingLimitMaximumValue, bookingLimitsMaximumUnit } = centreBookingLimits;
      const maximumDateInAdvance = moment().add(
        bookingLimitMaximumValue,
        bookingLimitsMaximumUnit,
      );

      if (
        bookingLimitMaximumValue
        && bookingLimitsMaximumUnit
        && moment(selectedDay).isAfter(maximumDateInAdvance)
      ) {
        return null;
      }

      const providerServiceOfferRequest = _.times(visibleDays[1], (idx) => {
        const date = moment(selectedDay)
          .add(idx - visibleDays[0], 'days')
          .format(DATE_FORMAT);
        return appointmentService.getProviderServiceOffer(params, { bookingId: serviceId, date });
      });

      const providerOffers = await Promise.all(providerServiceOfferRequest);

      if (!providerOfferFetchedFirstTime) {
        const firstAvailableOffer = _.find(providerOffers, (offer) => _.some(offer, (timeSlot) => timeSlot.available));
        if (firstAvailableOffer) {
          const firstAvailableDate = firstAvailableOffer[0].date;

          if (selectedDay.format(DATE_FORMAT) !== firstAvailableDate) {
            setselectedDay(moment(firstAvailableDate, DATE_FORMAT));
            // setProviderOfferFetchedFirstTime(true);
            return setIsFetched(false);
          }
        } else {
          setselectedDay(
            moment(selectedDay).add(visibleDays[1], 'days').startOf('day'),
          );
          // setProviderOfferFetchedFirstTime(true);
          return setIsFetched(false);
        }
      }
      setproviderOffer(providerOffers);
      setfirstAvailableDate(selectedDay);

      nextProviderOffer.push(
        ..._.flatMap(providerOffer, (offer) => [...offer]),
      );
      firstDayOfNextWeek = moment(selectedDay).format(DATE_FORMAT);
      lastDayOfNextWeek = moment(moment(selectedDay).format(DATE_FORMAT))
        .add(visibleDays[1] - 1, 'day')
        .format(DATE_FORMAT);
      fetchNextProviderOffer();

      setProviderOfferFetchedFirstTime(true);
      setIsFetched(true);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchNextProviderOffer = async () => {
    try {
      const providerId = provider.id;
      const centreId = centre.id;
      const serviceId = service.booking_id;
      const centreBookingLimits = centre.CentreSetting;

      if (!providerId || !centreId || !serviceId) {
        return;
      }

      const params = {
        centreId,
        providerId,
      };

      const { bookingLimitMaximumValue, bookingLimitsMaximumUnit } = centreBookingLimits;
      const maximumDateInAdvance = moment().add(
        bookingLimitMaximumValue,
        bookingLimitsMaximumUnit,
      );

      if (
        bookingLimitMaximumValue
        && bookingLimitsMaximumUnit
        && moment(selectedDay).isAfter(maximumDateInAdvance)
      ) {
        return null;
      }

      firstDayOfNextWeek = moment(lastDayOfNextWeek)
        .add(1, 'day')
        .format(DATE_FORMAT);
      lastDayOfNextWeek = moment(firstDayOfNextWeek)
        .add(visibleDays[1] - 1, 'day')
        .format(DATE_FORMAT);

      const nextProviderServiceOffer = await appointmentService.getProviderServiceOffer(params, {
        bookingId: serviceId,
        start: firstDayOfNextWeek,
        end: lastDayOfNextWeek,
      });

      nextProviderOffer.push(...nextProviderServiceOffer);

      if (
        moment(lastDayOfNextWeek).isBefore(
          moment(selectedDay).add('2', 'month'),
        )
      ) {
        return fetchNextProviderOffer();
      }
      setNextProviderOfferFullArray(nextProviderOffer);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (preselectedProviderId) {
      const provider = providers.find(
        (provider) => provider.centreProvider_id === preselectedProviderId,
      );
      setprovider(provider);
    }
  }, [preselectedProviderId]);

  const onHandleProviderChoose = (event) => {
    const id = event.target.value;
    const provider = providers.find(
      (provider) => provider.centreProvider_id === id,
    );
    setprovider(provider);
  };

  useEffect(() => {
    if (!_.isEmpty(provider)) {
      const availableVariants = _.filter(serviceVariants, (service) => {
        const bookingProviders = _.get(service, 'BookingProvider', []);
        return _.some(
          bookingProviders,
          (bookingProvider) => bookingProvider.centreProvider_fk === provider.centreProvider_id,
        );
      });
      setserviceVariant(availableVariants);
    } else {
      setserviceVariant([]);
    }
    setIsFetched(false);
  }, [provider]);

  useEffect(() => {
    if (preselectedServiceId) {
      setservice(
        serviceVariant.find(
          (serviceVarian) => serviceVarian.service_id === preselectedServiceId,
        ),
      );
      setIsFetched(false);
    }
  }, [preselectedServiceId]);

  const onHandleServiceChoose = () => (event) => {
    const id = event.target.value;
    setservice(
      serviceVariant.find((serviceVarian) => serviceVarian.service_id === id),
    );
    setIsFetched(false);
  };

  const onTimeSlotClick = (timeSlot) => {
    sethighlightedTimeSlot(timeSlot, new Date(timeSlot.date).getDate());
    setDate(timeSlot);
  };

  const changeSelectedDay = (delta) => {
    if (delta === 0) {
      return;
    }

    const clonedSelectedDay = _.cloneDeep(selectedDay);
    const newSelectedDay = clonedSelectedDay.add(delta, 'day');

    if (isCalendarControlDisabled(delta)) {
      return;
    }

    const newSelectedWeekStart = moment(newSelectedDay);
    const newSelectedWeekEnd = moment(newSelectedDay).add(
      visibleDays[1] - 1,
      'day',
    );
    const selectedWeekDates = DateTime.getDaysListByStartEndMoment(
      newSelectedWeekStart,
      newSelectedWeekEnd,
    );

    const nextWeekProviderOffer = _.filter(
      nextProviderOfferFullArray,
      (offer) => moment(offer.date).isSameOrAfter(newSelectedWeekStart)
        && moment(offer.date).isSameOrBefore(newSelectedWeekEnd),
    );

    const sortedNextWeekProviderOffer = [];

    _.forEach(selectedWeekDates, (date, index) => {
      sortedNextWeekProviderOffer[index] = nextWeekProviderOffer.filter(
        (offer) => offer.date === date,
      );
    });

    setselectedDay(newSelectedDay);
    setproviderOffer(sortedNextWeekProviderOffer);

    // doesnt work becouse of Refactoring!
    if (
      moment(lastDayOfNextWeek).isBefore(
        moment(newSelectedDay).add('2', 'month'),
      )
    ) {
      fetchNextProviderOffer();
    }
  };

  const getCurrentMonth = () => {
    const monthName = moment(selectedDay).format('MMMM').toLowerCase();
    return t(`label_${monthName}`);
  };

  const isCalendarControlDisabled = (delta) => {
    const centreBookingLimits = centre.CentreSetting;
    const { bookingLimitMaximumValue, bookingLimitsMaximumUnit } = centreBookingLimits;

    const clonedSelectedDay = _.cloneDeep(selectedDay);
    const newSelectedDay = clonedSelectedDay.add(delta, 'day');
    const maximumDateInAdvance = moment().add(
      bookingLimitMaximumValue,
      bookingLimitsMaximumUnit,
    );

    return (
      moment(newSelectedDay)
        .add(visibleDays[1], 'day')
        .isSameOrBefore(moment(firstAvailableDate), 'day')
      || (bookingLimitMaximumValue
        && bookingLimitsMaximumUnit
        && moment(newSelectedDay).isAfter(maximumDateInAdvance))
    );
  };

  const handleClick = useCallback(
    (step) => {
      createSlot({
        provider_fk: provider.centreProvider_id,
        startTime: dateSlot.startTime,
        endTime: dateSlot.endTime,
        date: dateSlot.date,
        booking_fk: service.booking_id,
      }).then((response) => {
        setBookedInformation(response.data);
        click(step);
      });
    },
    [provider, dateSlot, service],
  );

  const setDayName = (delta) => {
    const clonedSelectedDay = _.cloneDeep(selectedDay);
    const dayOfWeek = clonedSelectedDay.add(delta, 'days').day();

    return t(WEEK_NAMES[dayOfWeek]);
  };

  const setDay = (delta) => {
    const clonedSelectedDay = _.cloneDeep(selectedDay);

    return clonedSelectedDay.add(delta, 'days').format('DD');
  };

  const handleGoBack = useCallback(() => {
    if (bookedInfomation) {
      deleteSlot(bookedInfomation.appointmentBookedSlot_id).then(() => {
        setBookedInformation(null);
        setOpenModal(false);
        click(1);
      });
    }
  }, [bookedInfomation]);

  const renderCountDown = useMemo(() => {
    if (bookedInfomation) {
      const exipredTime = moment(bookedInfomation.createdAt).add(2, 'minute');
      return (
        <Countdown exipredTime={exipredTime} onEnd={() => setOpenModal(true)} />
      );
    }
    return null;
  }, [bookedInfomation]);

  return (
    <>
      {visible === true ? (
        <ith-div class="border mt-3">
          <ith-div
            class="flex justify-between md:p-5 p-3 md:py-6 py-4 cursor-pointer select-none w-full"
            onClick={() => {
              !checked ? handleGoBack() : '';
            }}
          >
            <ith-div class="font-semibold text-gray-300 tracking-tight w-full">
              <ith-div>
                <ith-p class="flex">
                  <ith-div class="gridColor text-white flex items-center justify-center rounded-full mr-5 text-md h-6 w-6">
                    1
                  </ith-div>
                  {t('bookingStep1')}
                </ith-p>
                {show === false ? (
                  <ith-div class="flex flex-col w-full">
                    <ith-p class="ml-12 mt-4 w-full">
                      <div className="flex items-center justify-between w-11/12">
                        <div>
                          {`${
                            _.find(providers, [
                              'centreProvider_id',
                              provider.centreProvider_id,
                            ]).User.firstName
                          } ${
                            _.find(providers, [
                              'centreProvider_id',
                              provider.centreProvider_id,
                            ]).User.lastName
                          }`}
                        </div>
                        {renderCountDown}
                      </div>
                    </ith-p>
                    <ith-p class="ml-12">
                      {`${service.name}, ${service.duration} min ${getPrice(
                        service,
                        centre,
                      )}`}
                    </ith-p>
                    <ith-p class="ml-12">
                      {`${moment(dateSlot.date).format('LL')} ${
                        dateSlot.startTime
                      }`}
                    </ith-p>
                  </ith-div>
                ) : (
                  ''
                )}
              </ith-div>
            </ith-div>
            {show === false && checked === false ? (
              <MdEdit className="text-xl text-gray-300" />
            ) : (
              ''
            )}
            {checked === true ? (
              <CheckCircleIcon className="text-green-700" />
            ) : (
              ''
            )}
          </ith-div>
          {show === true ? (
            <ith-div class="grid grid-cols-1 gap-4 items-center justify-between md:p-5 md:py-2 p-3 ">
              {!preselectedProviderSelectButHide && (
                <select
                  id="provider"
                  name="provider"
                  className="block w-full pl-4 pr-10 py-3.5 text-base border border-gray-400 focus:outline-none sm:text-sm"
                  onChange={onHandleProviderChoose}
                  disabled={preselectedProviderShowSelected}
                >
                  {_.map(providers, (item) => (
                    <option
                      selected={
                        item.centreProvider_id
                        === _.get(provider, 'centreProvider_id')
                      }
                      value={item.centreProvider_id}
                    >
                      {item.User.firstName}
                      {' '}
                      {item.User.lastName}
                    </option>
                  ))}
                </select>
              )}
              {!preselectedServiceSelectButHide && (
                <select
                  id="service"
                  name="service"
                  className="block w-full pl-4 pr-10 py-3.5 text-base border border-gray-400 focus:outline-none sm:text-sm"
                  onChange={onHandleServiceChoose()}
                  disabled={preselectedServiceShowSelected}
                >
                  {_.map(serviceVariant, (services) => (
                    <option
                      selected={services._id === preselectedServiceId}
                      value={services._id}
                    >
                      {services.name}
                      {' '}
                      {services.duration}
                      {' '}
                      min
                      {' '}
                      {getPrice(services, centre)}
                    </option>
                  ))}
                </select>
              )}
              <ith-div
                id="serviceDescription"
                class="block w-full pl-4 pr-10 py-3.5 text-base border border-gray-400 focus:outline-none sm:text-sm"
              >
                {_.isEmpty(service.description) ? (
                  <div>&nbsp;</div>
                ) : (
                  service.description
                )}
              </ith-div>
              <ith-div class="flex px-2 items-center justify-between">
                <IoIosArrowBack
                  className={`${isCalendarControlDisabled(-visibleDays[1]) ? 'cursor-not-allowed' : 'cursor-pointer'} text-gray-300 text-lg`}
                  onClick={() => changeSelectedDay(-visibleDays[1])}
                />
                <ith-p class="text-gray-300 text-sm text-center font-semibold py-3">
                  {getCurrentMonth()}
                  {' '}
                  {selectedDay.year()}
                </ith-p>
                <IoIosArrowForward
                  className={`${isCalendarControlDisabled(+visibleDays[1]) ? 'cursor-not-allowed' : 'cursor-pointer'} text-gray-300 text-lg`}
                  onClick={() => changeSelectedDay(+visibleDays[1])}
                />
              </ith-div>
              <ith-div class="bg-white overflow-hidden overflow-x-auto">
                <ith-div class="flex flex-row">
                  {/* ToDo: do it like in custom */}
                  {_.times(visibleDays[1], (idx) => {
                    const delta = idx - visibleDays[0];

                    return (
                      <ith-div class="flex flex-col flex-grow">
                        <ith-div class="px-6 py-3 bg-gray-50 text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <ith-div class="flex flex-col w-full items-center">
                            <ith-div class="font-bold tracking-tight text-gray-300">
                              {/* {getDayName(providerOffer[idx][0].date)} */}
                              {setDayName(delta)}
                            </ith-div>
                            {/* <ith-div class="mt-0.5">{moment(providerOffer[idx][0].date).format('DD')}</ith-div> */}
                            <ith-div class="mt-0.5">{setDay(delta)}</ith-div>
                          </ith-div>
                        </ith-div>
                        {!_.isEmpty(providerOffer[idx])
                          && _.map(
                            _.orderBy(providerOffer[idx], 'startTime'),
                            (timeSlot) => {
                              const processTimeSlot = checkAvailable(
                                timeSlot,
                                listPreBooked,
                              );
                              return (
                                processTimeSlot.available && (
                                  <ith-div
                                    class="text-sm py-1.5 px-3 text-gray-500"
                                    onClick={() => !processTimeSlot.booked
                                      && onTimeSlotClick(timeSlot)}
                                  >
                                    <ith-div class="text-sm flex items-center justify-center text-gray-500">
                                      <ith-div
                                        class={`${highlightedTimeSlot.startTime === timeSlot.startTime && highlightedTimeSlot.date === timeSlot.date ? 'border-green-100' : ''} rounded-lg flex items-center justify-center w-20 md:w-16 h-12 md:h-9 md:font-semibold text-sm border-2 border-transparent p-2 ${processTimeSlot.booked ? 'cursor-not-allowed' : 'cursor-pointer hover:border-green-100'}`}
                                      >
                                        {timeSlot.startTime}
                                      </ith-div>
                                    </ith-div>
                                  </ith-div>
                                )
                              );
                            },
                          )}
                      </ith-div>
                    );
                  })}
                </ith-div>
              </ith-div>
              <ith-div class="flex justify-center md:justify-end pt-5">
                <ith-button
                  onClick={
                    highlightedTimeSlot ? () => handleClick(2) : () => {}
                  }
                  class={`${highlightedTimeSlot ? 'cursor-pointer' : 'cursor-not-allowed'} gridColor inline-flex justify-center w-32 items-center px-2 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white focus:outline-none`}
                >
                  {t('label_next')}
                </ith-button>
              </ith-div>
            </ith-div>
          ) : (
            <></>
          )}
          <Modal
            open={openModal}
            title="Timeout"
            onCancel={handleGoBack}
            destroyOnClose
            footer={<Button onClick={handleGoBack}>OK</Button>}
          >
            <div>You ran out of time, please choose another time slot</div>
          </Modal>
        </ith-div>
      ) : (
        <></>
      )}
    </>
  );
}

// dynamically change amount of visibleDays
function useVisibleDay() {
  const [visibleDays, setvisibleDays] = useState([0, 7]);

  useEffect(() => {
    const getVisibleDays = () => {
      const width = window.innerWidth;
      if (width <= 650) {
        setvisibleDays([0, 3]);
      } else if (width <= 1150) {
        setvisibleDays([0, 5]);
      } else {
        setvisibleDays([0, 7]);
      }
    };
    window.addEventListener('resize', getVisibleDays);
    return () => {
      window.removeEventListener('resize', getVisibleDays);
    };
  });
  return visibleDays;
}

export default BookingScreen;
