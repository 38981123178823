/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

function Verification({
  visible, click, setCode, setSendCode, t,
}) {
  const checkCode = (value) => {
    if (value.length === 4) {
      setCode(value);
      click(1);
    }
  };

  return (
    <>
      {visible === true ? (
        <ith-div class="border mt-3">
          <ith-div class="flex justify-between md:p-5 p-3 md:py-6 py-4 cursor-pointer select-none">
            <ith-div class="font-semibold text-gray-300 tracking-tight ">
              <ith-div>
                <ith-p class="flex">
                  <ith-div class="gridColor text-white flex items-center justify-center rounded-full mr-5 text-md h-6 w-6">
                    4
                  </ith-div>
                  {t('label_confirm')}
                </ith-p>
                <ith-div class="grid lg:grid-cols-2 grid-cols-1 items-end">
                  <ith-div class=" ml-12 mb-4 mt-4">
                    <label
                      className="block text-gray-700 text-sm mb-2"
                      htmlFor="verificationCode"
                    >
                      {t('auth.enterVerificationCode')}
                      {' '}
                      <span className="text-red-600">*</span>
                    </label>
                    <input
                      className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      id="verificationCode"
                      type="text"
                      placeholder={t('auth.enterVerificationCode')}
                      onChange={(e) => checkCode(e.target.value)}
                    />
                  </ith-div>
                  <ith-div class="flex flex-col ml-4 mb-4 mt-2">
                    <ith-p className="md:text-md text-sm ">
                      {t('pleaseEnterCode')}
                    </ith-p>
                    <ith-p>
                      {t('codeNotReceived')}
                      {' '}
                      <span
                        onClick={() => setSendCode()}
                        className="text-red-400"
                      >
                        {t('label_requestAgain')}
                      </span>
                    </ith-p>
                  </ith-div>
                </ith-div>
              </ith-div>
            </ith-div>
          </ith-div>
        </ith-div>
      ) : (
        <></>
      )}
    </>
  );
}
export default Verification;
