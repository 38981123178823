import { Col, List, Row } from 'antd';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import GMapContainer from '../../../components/GMapContainer/GMapContainer';
import ListCard from '../../../components/listingCard/ListCard';
import StoreConnector from '../../../hoc/StoreConnector';
import withParams from '../../../hoc/withParams';
import providerPlaceholderImage from '../../../placeholders/1.png';
import {
  getResultsThunk,
  invalidateResultsThunk,
} from '../../../redux/thunks/search';
import stateUtils from '../../../utils/state';
import './styles.css';

const data = [
  {
    providerName: 'Anna Meier',
    centreName: 'Praxis ABC Luzern',
    tags: ['TCM', 'Shiatsu', 'Akupunktur', 'Cranio'],
    nextAppointmentDate: new Date('2024-03-21'),
    distance: '250 m',
    providerImage: providerPlaceholderImage,
    averageRating: 4.4,
  },
];

const formatResults = (results) => {
  const formattedResults = [];

  _.map(results, (result) => {
    const formattedTags = [];

    _.map(result.Service, (service) => {
      if (!formattedTags.includes(service.name.split(',')[0])) {
        formattedTags.push(service.name.split(',')[0]);
      }
    });

    formattedResults.push({
      centreId: result.Centre.centre_id,
      providerName: `${result.User.firstName} ${result.User.lastName}`,
      centreName: result.Centre.name,
      tags: formattedTags,
      nextAppointmentDate: new Date('2022-07-21'),
      distance: '1.2 km',
      providerImage: result.User.Avatar ? result.User.Avatar.url : '',
      bookable: result.bookable,
    });
  });

  return formattedResults;
};

class SearchListing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      results: data,
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  componentWillUnmount() {
    this.props.dispatch(invalidateResultsThunk());
  }

  reflectProps(props) {
    if (
      !props.isFetchingResults
      && !props.isValidResults
      && !props.isFailedFetchingResults
    ) {
      props.dispatch(getResultsThunk(props.params.query));
    }
    if (props.isValidResults && props.results.length > 0) {
      this.setState({ results: formatResults(this.props.results) }, () => this.setState({ results: formatResults(this.props.results) }));
    }
  }

  render() {
    const { query } = this.props.params;
    const { results } = this.state;

    return (
      <div>
        <Row style={{ flexWrap: 'wrap-reverse' }}>
          <Col span={24} md={16} lg={14}>
            <div className="class-container">
              <div>
                <p className="resultsFor">{`Resultate für: ${query}`}</p>
              </div>
              <List
                dataSource={results}
                pagination={{
                  position: 'bottom',
                  pageSize: 4,
                }}
                loading={!this.props.isValidResults}
                renderItem={(item) => (
                  <div className="pt-3">
                    <ListCard
                      navigatePath={`/profile/${item.centreId}`}
                      providerName={item.providerName}
                      centreName={item.centreName}
                      tags={item.tags}
                      nextAppointmentDate={item.nextAppointmentDate}
                      distance={item.distance}
                      providerImage={item.providerImage}
                      averageRating={item.averageRating}
                      bookable={item.bookable}
                    />
                  </div>
                )}
              />
            </div>
          </Col>
          <Col span={24} md={8} lg={10}>
            <div className="maps-container">
              <GMapContainer />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreConnector(
  withParams(SearchListing),
  stateUtils.fullStateMap,
  {},
);
