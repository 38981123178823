import {
  commonClient,
  publicCommonClient,
} from '../clients';

const authInterface = {
  getApplicationSettings: async () => {
    const { data: appData } = await publicCommonClient.get('/application');

    return appData.settings;
  },
  getUserFromToken: async () => {
    const { data: response } = await commonClient.get(
      '/api/auth/getUserFromToken',
    );

    return response.user;
  },
  signIn: async (data) => {
    const { data: response } = await publicCommonClient.post(
      '/api/auth/signin',
      data,
    );

    return response;
  },
  signUp: async (data) => {
    const { data: response } = await publicCommonClient.post(
      '/api/auth/consumerSignup',
      data,
    );

    return response;
  },
  sendActivationCode: async (data) => {
    const { data: response } = await publicCommonClient.post(
      '/api/auth/sendActivationCode',
      data,
    );

    return response;
  },
  activateWithCode: async (data) => {
    const { data: response } = await publicCommonClient.post(
      '/api/auth/activateWithCode',
      data,
    );

    return response;
  },
  sendReset: async (data) => {
    const { data: response } = await publicCommonClient.post(
      '/api/auth/sendReset',
      data,
    );

    return response;
  },
  resetPassword: async (data) => {
    const { data: response } = await commonClient.post(
      '/api/auth/resetPassword',
      data,
    );

    return response;
  },
  getHashedInvoice: async (hashedContact) => {
    const { data: response } = await publicCommonClient.get(
      `/api/contacts/hash/${hashedContact}`,
    );
    return response;
  },
};

export default authInterface;
