/* eslint-disable react/no-this-in-sfc */

/* eslint-disable react/no-unescaped-entities */
import {
  Button,
  Col,
  Image,
  Row, // Avatar,
  Space,
} from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

// import { UserOutlined } from '@ant-design/icons';

// import { StarFilled } from '@ant-design/icons';
// import { InfoCircleOutlined } from '@ant-design/icons';

function ListCard({
  navigatePath,
  providerName,
  centreName,
  tags,
  nextAppointmentDate,
  distance,
  providerImage,
  bookable,
  // averageRating,
}) {
  const navigate = useNavigate();
  const styleCard = {
    backgroundSize: '100%',
    borderRadius: '100px',
    width: '170px',
    height: '170px',
    objectFit: 'cover',
    fontSize: '160px',
    padding: '5px',
  };
  const createPlaceholderImage = (name) => {
    const nameString = name.split(' ');
    const firstNameLetter = Array.from(nameString[0])[0];
    const lastNameLetter = Array.from(nameString[1])[0];
    return `${firstNameLetter} ${lastNameLetter}`;
  };
  const formatTags = (unformatedTags) => {
    const formated = [];
    unformatedTags.forEach((tag) => {
      if (!formated.includes(tag.split(',')[0])) {
        formated.push(tag.split(',')[0]);
      }
    });
    return formated;
  };
  const formatedTags = formatTags(tags);
  return (
    <>
      <a href={bookable ? navigatePath : null} className="mdOnly bg searchListingLink">
        <Row style={{ height: '175px' }}>
          <Col span={9}>
            {providerImage ? (
              <Image
                src={providerImage}
                width="100%"
                preview={false}
                style={styleCard}
              />
            ) : (
              <div className="placeholderImage">
                {createPlaceholderImage(providerName)}
              </div>
            )}
          </Col>
          <Col span={15} style={{ padding: '10px' }}>
            <Row>
              <Col span={18} style={{ display: 'flex' }}>
                <p className="praxis-abc-luzern">{centreName}</p>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <p className="distance">{distance}</p>
                {/* Rating for future implementation */}
                {/* <Row justify="end" align="middle">
                  <StarFilled style={{ marginRight: '5px', color: '#EB5E55' }} />
                  <p className="averageRating">{`${averageRating} (xy)`}</p>
                </Row> */}
              </Col>
            </Row>
            <Row>
              <p className="providerName">{providerName}</p>
            </Row>
            <Row style={{ height: '45px' }}>
              <Space>
                {formatedTags.length > 0 && (
                  <p className="services">{formatedTags[0]}</p>
                )}
                {formatedTags.length > 1 && (
                  <>
                    <p className="services">·</p>
                    <p className="services">{formatedTags[1]}</p>
                  </>
                )}
                {formatedTags.length > 2 && (
                  <>
                    <p className="services">·</p>
                    <p className="services">{formatedTags[2]}</p>
                  </>
                )}
              </Space>
            </Row>
            {bookable && (
              <Row>
                <Col span={14} style={{ display: 'flex' }}>
                  <p className="termine-ab">Termine ab</p>
                  <p className="datum">
                    {nextAppointmentDate.toLocaleDateString()}
                  </p>
                </Col>
                <Col span={10} style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => {
                      navigate(navigatePath);
                    }}
                    style={{
                      color: '#EB5E55',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: '700',
                      padding: '0px',
                      border: 'none',
                      display: 'contents',
                    }}
                  >
                    jetzt Buchen
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </a>
      <a href={bookable ? navigatePath : null} className="mobileOnly bg">
        <Row style={{ height: '100px', padding: '8px' }}>
          <Col span={6}>
            {providerImage ? (
              <Image
                src={providerImage}
                width="100%"
                preview={false}
                style={styleCard}
              />
            ) : (
              <div className="placeholderImage">
                {createPlaceholderImage(providerName)}
              </div>
            )}
          </Col>
          <Col span={18}>
            <Row>
              <p className="listingProviderName">{providerName}</p>
            </Row>
            <Row style={{ paddingBottom: '15px' }}>
              <p className="listingCentreName">{centreName}</p>
            </Row>
            {bookable && (
              <Row justify="space-between">
                <Col style={{ display: 'flex' }}>
                  <p className="listingText">Termine ab</p>
                  <p className="listingDate">
                    {nextAppointmentDate.toLocaleDateString()}
                  </p>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <Button
                    onClick={() => {
                      navigate(navigatePath);
                    }}
                    style={{
                      color: '#EB5E55',
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      lineHeight: '18px',
                      fontWeight: '700',
                      padding: '0px',
                      border: 'none',
                      display: 'contents',
                    }}
                  >
                    Buchen
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </a>
    </>
  );
}

export default ListCard;
