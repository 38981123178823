/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

function PasswordForgoten({
  visible, click, setEmail, t,
}) {
  return (
    <>
      {visible === true ? (
        <ith-div class="border mt-3">
          <ith-div
            class="flex justify-between md:p-5 p-3 md:py-6 py-4 cursor-pointer select-none"
            onClick={() => click(1)}
          >
            <ith-div class="font-semibold text-gray-300 tracking-tight ">
              <ith-div>
                <ith-p class="flex">
                  <ith-div
                    class="text-gray-200 flex items-center justify-center rounded-full mr-5 text-md h-6 w-6"
                  >
                    X
                  </ith-div>
                  {t('auth.forgotPassword')}
                </ith-p>
              </ith-div>
            </ith-div>
          </ith-div>
          <ith-div class="grid grid-cols-1 gap-4 items-center justify-between md:p-5 md:py-1 p-3 ">
            <ith-p className="text-lg ">{t('label_forgotPasswordText')}</ith-p>
          </ith-div>
          <ith-div class="grid lg:grid-cols-3  md:grid-cols-3 gris-cols-1 flex items-end pl-8 md:mr-1 mr-8">
            <ith-div class=" pl-4 mb-4 mt-2 lg:col-span-2 md:col-span-2">
              <label
                className="block text-gray-700 text-sm mb-1"
                htmlFor="username"
              >
                {t('label_email')}
                {' '}
                <span className="text-red-600">*</span>
              </label>
              <input
                className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                type="email"
                placeholder={t('placeholder_email')}
                onChange={(event) => setEmail(event.target.value)}
              />
            </ith-div>
            <ith-div class=" ml-4 mb-4 mt-2 flex md:justify-center justify-end">
              <ith-button
                onClick={() => click(2)}
                class="gridColor inline-flex justify-center w-32 items-center px-2 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-green-100 focus:outline-none"
              >
                {t('label_send')}
              </ith-button>
            </ith-div>
          </ith-div>
        </ith-div>
      ) : (
        <></>
      )}
    </>
  );
}
export default PasswordForgoten;
