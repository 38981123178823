/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import _ from 'lodash';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// function Password({ click, show, visible, checked, setPasswordSignUp, setRepeatPasswordSignUp, passwordSignUp, repeatPasswordSignUp, t }) {
function Password({
  click, show, visible, checked, setPasswordSignUp, passwordSignUp, t,
}) {
  const [validators, setValidators] = useState([
    { text: t('passwordRequirementsLength'), isValid: false, regex: /^.{8,}$/ },
    { text: t('passwordRequirementsNumber'), isValid: false, regex: /.*\d/ },
    { text: t('passwordRequirementsLowercase'), isValid: false, regex: /.*[a-z]/ },
    { text: t('passwordRequirementsCapital'), isValid: false, regex: /.*[A-Z]/ },
    { text: t('passwordRequirementsSpecial'), isValid: false, regex: /(?=.*?[_#?!@$%^&*])/ },
    { text: t('passwordRequirementsIsSame'), isValid: false },
  ]);
  const [repeatPasswordSignUp, setRepeatPasswordSignUp] = useState('');

  const onHandlePassword = () => (event) => {
    const validatorsToUpdate = _.cloneDeep(validators);
    const updatedValidators = _.map(validatorsToUpdate, (validator) => {
      if (validator.regex) {
        validator.isValid = event.target.value.match(validator.regex);
      }
      return validator;
    });
    setValidators(updatedValidators);
    setPasswordSignUp(event.target.value);
  };

  const onHandleRepeatPassword = () => (event) => {
    const validatorsToUpdate = _.cloneDeep(validators);
    const updatedValidators = _.map(validatorsToUpdate, (validator) => {
      if (!validator.regex) {
        validator.isValid = passwordSignUp === event.target.value;
      }
      return validator;
    });
    setValidators(updatedValidators);
    setRepeatPasswordSignUp(event.target.value);
  };

  const isPasswordValid = () => {
    const isValidatorsFalse = _.some(validators, (validator) => !validator.isValid);
    const isConfirmTheSame = passwordSignUp === repeatPasswordSignUp;

    return !isValidatorsFalse && isConfirmTheSame;
  };

  return (
    <>
      {visible === true ? (
        <ith-div class="border mt-3">
          <ith-div
            class="flex  justify-between md:p-5 p-3 md:py-6 py-4 cursor-pointer select-none"
            onClick={() => { !checked ? click(1) : ''; }}
          >
            <ith-div class="font-semibold text-gray-300 tracking-tight ">
              <ith-div>
                <ith-p class="flex">
                  <ith-div
                    class="gridColor text-white flex items-center justify-center rounded-full mr-5 text-md h-6 w-6"
                  >
                    3
                  </ith-div>
                  {t('auth.password')}
                </ith-p>
              </ith-div>
            </ith-div>
            {show === false && checked === false ? <MdEdit className="text-xl text-gray-300" /> : ''}
            {checked === true ? <CheckCircleIcon className="text-xl text-green-700" /> : ''}
          </ith-div>
          {show === true ? (
            <ith-div class="grid grid-cols-1 gap-4 items-center justify-between md:p-5 md:py-2 p-3  ">
              <ith-div class="grid grid-cols-12 items-start pl-8 ">
                <ith-div class="  md:col-span-8  col-span-12">
                  <ith-div class="md:flex md:mr-1 mr-10">
                    <ith-div class=" pl-4 mb-4 mt-2  w-full  ">
                      <label
                        className="block text-gray-700 text-sm mb-1"
                        htmlFor="username"
                      >
                        {t('auth.password')}
                        {' '}
                        <span className="text-red-600">*</span>
                      </label>
                      {/* toDo: change type to password */}
                      <input
                        className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="username"
                        type="password"
                        value={passwordSignUp}
                        placeholder={t('placeholder_password')}
                        onChange={onHandlePassword()}
                      />
                    </ith-div>
                    <ith-div class=" pl-4 mb-4 mt-2  w-full ">
                      <label
                        className="block text-gray-700 text-sm mb-1"
                        htmlFor="username"
                      >
                        {t('label_repeatPassword')}
                        {' '}
                        <span className="text-red-600">*</span>
                      </label>
                      <input
                        className="appearance-none border rounded w-full py-1.5 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="username"
                        type="password"
                        value={repeatPasswordSignUp}
                        placeholder={t('placeholder_password')}
                        onChange={onHandleRepeatPassword()}
                      />
                    </ith-div>
                  </ith-div>
                  <ith-div class="grid lg:grid-col-2 md:grid-cols-2 grid-col-1">
                    <ith-div class="pl-4  mb-5 ">
                      {validators.map((item) => (
                        <ith-p class="flex mt-3 items-center text-sm">
                          <span className={`${item.isValid ? 'bg-green-100' : 'bg-red-100'} text-white flex items-center justify-center rounded-full mr-1 text-xs h-5 w-5`}>
                            {item.isValid ? '✓' : 'x'}
                          </span>
                          {item.text}
                        </ith-p>
                      ))}
                    </ith-div>
                    <ith-div class="text-sm mt-3 md:pl-4 pl-0">
                      <ith-p>
                        {
                          t('label_policy1')
                          + t('label_termsOfServices')
                          + t('label_policy2')
                          + t('label_privacyPolicy')
                          + t('label_policy3')
                        }
                      </ith-p>
                    </ith-div>
                  </ith-div>
                </ith-div>

                <ith-div class=" ml-8 mb-4 mt-8  flex md:col-span-4 col-span-12 md:justify-center justify-end">
                  <ith-button
                    onClick={isPasswordValid() ? () => click(2) : () => {}}
                    class={`${isPasswordValid() ? 'gridColor cursor-pointer' : 'cursor-not-allowed'} inline-flex justify-center w-32 items-center px-2 py-1 border border-transparent text-sm font-medium rounded shadow-sm text-white bg-green-100 focus:outline-none`}
                  >
                    {t('label_next')}
                  </ith-button>
                </ith-div>
              </ith-div>
            </ith-div>
          ) : (
            <></>
          )}
        </ith-div>
      ) : (
        <></>
      )}
    </>
  );
}
export default Password;
