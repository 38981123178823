/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Provider } from 'react-redux';

import config from '../../config/env';
import Minmal from '../../containers/MinimalWidget/Content';
import EmbedLoader from '../src/components/EmbedLoader';
import withI18next from '../src/hoc/withI18next';
import initStore from '../src/store';
import reducers from '../src/store/reducers';
import { fetchProviders, fetchWidget } from '../src/utils/booking';

const store = initStore(reducers, {}, false);

export const MinimalWidgetPage = withI18next(['home'])(
  class MinimalWidget extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        loading: true,
        error: false,
        centre: null,
        checkedVariants: [],
        serviceVariants: [],
        services: [],
        providers: [],
        widget: null,
      };
    }

    componentDidMount() {
      document.title = `${config.config.brandName} | ${this.props.t('button_bookNow')}`;
      const script = document.createElement('script');
      script.id = 'tailwind-css';
      script.src = 'https://cdn.tailwindcss.com';
      script.async = true;
      script.defer = true;
      document.body.append(script);
      const element = document.getElementById('embed-booking-page');

      const widgetId = element.getAttribute('widget-id');

      if (this.state.loading) {
        fetchWidget(widgetId).then((widget) => {
          // eslint-disable-next-line react/no-unused-state
          this.setState({ widget });
          fetchProviders(widget.centre_fk).then((result) => this.setState(result));
        });
      }
    }

    render() {
      if (this.state.error) {
        return <div>{this.state.error}</div>;
      }

      if (this.state.loading) {
        return <EmbedLoader isLoading={this.state.loading} />;
      }

      const {
        centre, checkedVariants, serviceVariants, providers, services,
      } = this.state;

      const element = document.getElementById('embed-booking-page');

      const {
        preselectedProviderId,
        preselectedProviderShowSelected,
        preselectedProviderSelectButHide,
        preselectedServiceId,
        preselectedServiceShowSelected,
        preselectedServiceSelectButHide,
      } = this.state.widget;

      return (
        <Provider store={store}>
          <div id="minimalWidget">
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/static/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={`${config.api.consumerApps}/static/favicon-16x16.png`}
            />
            <link
              rel="manifest"
              href={`${config.api.consumerApps}/static/manifest.json`}
            />
            <link
              rel="mask-icon"
              href={`${config.api.consumerApps}/static/safari-pinned-tab.svg`}
              color="#0096ff"
            />
            <meta
              name="apple-mobile-web-app-title"
              content={config.config.brandName}
            />
            <meta name="application-name" content={config.config.brandName} />
            <meta name="theme-color" content="#0096FF" />

            <link
              rel="stylesheet"
              href={`${config.api.consumerApps}/static/css/antdStyles.css`}
            />
            <link
              rel="stylesheet"
              href={`${config.api.consumerApps}/static/css/normalize.css`}
            />
            <link
              rel="stylesheet"
              href={`${config.api.consumerApps}/static/css/index.css`}
            />
            <link
              rel="stylesheet"
              href={`${config.api.consumerApps}/static/css/tailwind.css`}
            />
            <link
              href={`${config.api.consumerApps}/static/css/custom.css`}
              rel="stylesheet"
              type="text/css"
              media="all"
            />
            <link
              rel="stylesheet"
              href={`${config.api.providerApps}/css/minimal_external_${element.getAttribute('widget-id') || element.getAttribute('data-centre-id')}.css`}
            />
            <link
              href="https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,400,300,600,700%7CRaleway:700"
              rel="stylesheet"
              type="text/css"
            />
            <div>
              <Minmal
                centre={centre}
                services={services}
                providers={providers}
                checkedVariants={checkedVariants}
                serviceVariants={serviceVariants}
                t={this.props.t}
                preselectedProviderId={preselectedProviderId}
                preselectedProviderShowSelected={
                  preselectedProviderShowSelected
                }
                preselectedProviderSelectButHide={
                  preselectedProviderSelectButHide
                }
                preselectedServiceId={preselectedServiceId}
                preselectedServiceShowSelected={preselectedServiceShowSelected}
                preselectedServiceSelectButHide={
                  preselectedServiceSelectButHide
                }
              />
            </div>
          </div>
        </Provider>
      );
    }
  },
);
